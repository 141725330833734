import { Link } from "react-router-dom";
import { getFooterData } from './utility';
import TrulyLegit from "./trust_seals/truly_legit";
// import TruelyLegit from "./trust_seals/truely_legit";
import PayPalLogo from "./trust_seals/paypal"
import FiveYears from "./trust_seals/5years"
import AuthApproved from "./trust_seals/auth_net";

const FooterPayment = () => {
  const footerData = getFooterData(); // Retrieve footer data using your utility function
  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer className="w-full bg-blue-950 bottom-0">
      <div className="all-seals flex max-w-3xl mx-auto md:gap-x-16 justify-center px-6 pt-6 pb-0">
          <div><TrulyLegit/></div>
          <div><FiveYears/></div>
          <div><PayPalLogo/></div>
        </div>
      <div className="md:flex max-w-7xl mx-auto md:gap-x-16 first-line: md:justify-between md:p-6 p-6">
        <div className="max-w-5xl mx-auto md:text-start text-center text-blue-50 text-xs">
          {/* <div className="text-xs">
            {footerData.disclaimer}
          </div> */}
          <div className=" mt-4">
            <span className="mr-1 font-semibold">{footerData.copyrightIcon} 2018 -  {year} {footerData.copyright}.</span>{" "} 
            {/* <Link to={footerData.termsPrivacyLink} className="underline">
              Terms & Privacy
            </Link> */}
            <br className="md:hidden" />
          </div>
          <div>
            
          </div>
        </div>
        {/* <div>
          <Link to={footerData.homeLink} className="text-center">
            <span className="sr-only">{footerData.siteName}</span>
            <img
              className="w-128 max-sm:w-auto h-6 md:mt-0 mt-4 mx-auto"
              src={footerData.trustedSiteImage}
              alt="trusted site"
            />
          </Link>
        </div> */}
      </div>
    </footer>
  );
};
export default FooterPayment;
